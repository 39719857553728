<template>
    <div>

        <template>
          <div id="breadcrumb-alignment" class="mb-1">
            <div class="d-flex justify-content-start breadcrumb-wrapper">
              <b-breadcrumb
                :items="breadCrumb()"
              />
            </div>
          </div>
        </template>

        <b-row class="match-height">
            <b-col lg="12" md="12">
        
                <b-card title="Add Location">

                    <b-alert v-model="showDismissibleAlert" variant="danger" dismissible class="mb-1">
                        <div class="alert-body">
                            {{error_message}}
                        </div>
                    </b-alert>
                
                    <b-form @submit.prevent="formSubmit">
                        <b-row>
                            <b-col md="12">        
                                <b-form-group label="Project Site" class="required">
                                    
                                    <v-select
                                        v-model="form.site"
                                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                        label="site_name"
                                        :options="sites"
                                        placeholder="Select"
                                        @input="getBuilding()"
                                        :clearable="false"
                                    >
                                      <template #selected-option="{ site_name }">
                                        <span v-if="site_name.length < 23">{{site_name}}</span>
                                        <span v-if="site_name.length >= 23">{{site_name.substring(0, 23) + '...'}}</span>
                                      </template>
                                    </v-select>
                                </b-form-group>
                                    
                                   
                                <b-form-group label="Building" class="required">
                                    
                                    <v-select
                                        v-model="form.building"
                                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                        label="name"
                                        :options="buildings"
                                        placeholder="Select"
                                        @input="getLevels()"
                                        :clearable="false"
                                    >
                                      <template #selected-option="{ name }">
                                        <span v-if="name.length < 23">{{name}}</span>
                                        <span v-if="name.length >= 23">{{name.substring(0, 23) + '...'}}</span>
                                      </template>
                                    </v-select>
                                </b-form-group>
                                <b-form-group label="Level" class="required">
                                    
                                    <v-select
                                        v-model="form.level"
                                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                        :options="levels"
                                        placeholder="Select"
                                        :clearable="false"
                                    >
                                    </v-select>
                                </b-form-group>

                                <b-form-group label="Form Types" class="required">
                                    
                                    <v-select
                                        v-model="form.form_type"
                                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                        :options="form_types"
                                        multiple
                                        placeholder="Select"
                                        :close-on-select=false
                                        :clear-on-select=false
                                        class="multiselect_muliple_options"
                                    >
                                    </v-select>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <!-- <b-row class="mt-1">
                            <b-col :md="req_code2 ? checkForm() ? '6': '11' : '6'">
                                <h5>Location Name <span class="text-danger">*</span></h5>
                            </b-col>
                            
                            <b-col :md="req_code2 ? '5' : '5'" v-if="checkForm()">
                                <h5>Toilet Type <span class="text-danger">*</span></h5>
                            </b-col>
                            <b-col md="1">

                            </b-col>

                        </b-row> -->

                        <div v-if="checkForm() == false" class="fsmTable table-responsive mt-1" style="margin-bottom: 0px !important;">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th scope="col" style="width: 96%;">Location Name</th>
                                        <th scope="col" style="width: 4%"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(fm, index) in form.locations" :key="index">
                                        <td>
                                            <b-form-input autocomplete="off" placeholder="3 to 100 characters" v-model="fm.name" />
                                        </td>
                                        <td class="text-center">
                                            <b-button variant="danger" @click="removeRow(index);">-</b-button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <hr>
                        </div>
                        
                        <div v-if="checkForm() == true" class="fmTable table-responsive mt-1" style="margin-bottom: 0px !important;">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th scope="col" style="width: 46%;">Location Name <span class="text-danger">*</span></th>
                                        <th scope="col" style="width: 50%;">Toilet Type <span class="text-danger">*</span></th>
                                        <th scope="col" style="width: 4%"></th>
                                    </tr>
                                </thead>
                                <tbody v-for="(fm, index) in form.locations" :key="index">
                                    <tr>
                                        <td class="pt-2">
                                            <b-form-input autocomplete="off" placeholder="3 to 100 characters" v-model="fm.name"/>
                                        </td>
                                        <td class="pt-2">
                                            <v-select
                                                v-model="fm.type"
                                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                multiple
                                                :options="location_types"
                                                placeholder="Select"
                                                @input="sortLocType(index)"
                                                @option:selected="addLocationType($event, index)"
                                                @option:deselected="removeLocationType($event, index)"
                                                :appendToBody=true
                                                :close-on-select=false
                            :clear-on-select=false
                                            />
                                        </td>
                                        <td class="text-center pt-1">
                                            <b-button variant="danger" @click="removeRow(index);">-</b-button>
                                        </td>
                                    </tr>
                                    <tr v-if="req_code2 && fm.type.length > 0" class="" style="margin-bottom: 0px !important;">
                                        <td colspan="3">  
                                            <div class="fbDetail p-2 rounded">

                                                <table class="table">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col" style="width: 30%;">Toilet Name <span class="text-danger">*</span></th>
                                                            <th scope="col" style="width: 35%;">Code <span class="text-danger">*</span></th>
                                                            <th scope="col" style="width: 35%;">No. of Area <span class="text-danger">*</span></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="(ltd, ind) in fm.location_type_detail" :key="ind">
                                                            <td>{{ltd.name}}</td>
                                                            <td>
                                                                <b-form-input autocomplete="off" placeholder="3 to 50 characters" v-model="ltd.unique_id" :key="ind+index"/>
                                                            </td>
                                                            <td>
                                                                <b-form-input autocomplete="off" placeholder="1-20" v-model="ltd.num_area" :key="ind+'_'+index"/>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <hr>
                        </div>
                        
                        <!-- <b-row v-for="(fm, index) in form.locations" :key="index" class="mt-1">

                            <b-col :md="req_code2 ? checkForm() ? '6': '11' : '6'">
                                <b-form-input autocomplete="off" placeholder="3 to 100 characters" v-model="fm.name" />
                            </b-col>

                            <b-col :md="req_code2 ? '5' : '5'" v-if="checkForm()">
                                
                                <v-select
                                    v-model="fm.type"
                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                    multiple
                                    :options="location_types"
                                    placeholder="Select"
                                    @input="sortLocType(index)"
                                    @option:selected="addLocationType($event, index)"
                                    @option:deselected="removeLocationType($event, index)"
                                />
                            </b-col>
                            <b-col md="1">
                                <b-button variant="danger" @click="removeRow(index);">-</b-button>
                            </b-col>
                            <b-col cols="12" v-if="req_code2 && fm.type.length > 0">
                                    <b-row class="mt-2">
                                        <b-col cols="3">
                                            <h5>Toilet Name <span class="text-danger">*</span></h5>
                                        </b-col>
                                        <b-col cols="3">
                                            <h5>Code <span class="text-danger">*</span></h5>
                                        </b-col>
                                        <b-col cols="3">
                                            <h5>No. of Area <span class="text-danger">*</span></h5>
                                        </b-col>
                                    </b-row>
                                    <b-row class="mt-1" v-for="(ltd, ind) in fm.location_type_detail" :key="ind">
                                        <b-col cols="3">{{ltd.name}}</b-col>
                                        <b-col cols="3">
                                            <b-form-input autocomplete="off" placeholder="3 to 50 characters" v-model="ltd.unique_id" :key="ind+index"/>
                                        </b-col>
                                        <b-col cols="3">
                                            <b-form-input autocomplete="off" placeholder="1-20" v-model="ltd.num_area" :key="ind+'_'+index"/>
                                        </b-col>
                                    </b-row>
                                <hr/>
                            </b-col>

                        </b-row> -->

                    
                        <b-row class="mt-1">

                            <b-col md="11" class="text-center">
                                <!-- <hr> -->
                                <b-button class="mt-1 add_more_mrgn_mobile" variant="primary" @click="addRow">Add More</b-button>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <b-button v-ripple.400 = "'rgba(255, 255, 255, 0.15)'" type = "submit" variant = "warning" class = "mr-1">
                                    Submit
                                </b-button>

                                <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="outline-secondary" @click="$router.push({ name:'hand-sanitizer-locations',params:{site_id:$route.params.site,id:$route.params.building} })">
                                    Cancel
                                </b-button>
                            </b-col>
                        </b-row>
                    </b-form>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>
<script>
import {
    BCard, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton,BAlert,BFormSelect,BFormSelectOption,BFormText,BInputGroupPrepend,BInputGroup,BMedia,BAvatar,BTable,BModal, BFormRadio,BFormTimepicker,BFormFile,BBreadcrumb,BFormTags
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { GET_API, POST_API } from "../../../store/actions.type"
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'
import vSelect from 'vue-select';
import AddImage from './AddImage.vue';
import Bus from "../../../event-bus";

export default {
    components: {
        BFormTags, BAlert, BCard, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton, BFormSelect, BFormSelectOption, BFormText, BInputGroupPrepend, BInputGroup, BMedia, BAvatar, BTable, BModal, VueCropper, BFormRadio, BFormTimepicker, VueTimepicker, vSelect, BFormFile,BBreadcrumb,AddImage
    },

    directives: {
        Ripple,
    },

    data() {
        return {        
            error_message:null,
            showDismissibleAlert:false,

            sites:[],
            buildings:[],
            form :{
                locations : [{name:'',/* unique_id:'',num_area:0, */type:[], location_type_detail:[]}],
                site : null,
                building : null,
                level:null,
                form_type:[]
            },
            levels:[],
            form_types:[],
            location_types:['Male','Female','Handicap','Other'],
            req_code:false,
            req_code2: false
        }
    },

    watch: { 
        'form.form_type': function(newVal, oldVal) { // watch it
            if(newVal.includes('Flash Times')){
                this.req_code2 = true;
            } 
            else if(newVal.includes('Soap Dispenser') || newVal.includes('Floor Traps') || newVal.includes('Air Freshener') || newVal.includes('Toilet Seat Sanitizer')){
                this.req_code2 = false;
            }
            else {
                this.req_code2 = false;
            }
        }
    },

    methods : {
        formSubmit(){

            return this.$store.dispatch(POST_API, {
                data:{
                    items : this.form,
                    req_code:this.req_code,
                    req_code2:this.req_code2
                },
                api : '/api/add-hand-sanitizer-loc'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message        = this.$store.getters.getErrors;
                    this.showDismissibleAlert = true;

                    window.scrollTo(0,0);
                } else {
                    this.showDismissibleAlert = false;

                    this.successAlert().then((result) => {
                        // this.$router.go(-1);
                        this.$router.push({ name:'hand-sanitizer-locations',params:{site_id:this.$route.params.site,id:this.$route.params.building} })
                    });
                }
            });
        },

        allSites(){
            return this.$store.dispatch(POST_API, {
                data:{
                    role:this.$store.getters.currentUser.role,
                    //om_sites:this.$store.getters.currentUser.om_sites,
                },
                api: '/api/all-sites'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    this.showDismissibleAlert = true;
                    window.scrollTo(0,0);
                } else {
                    this.showDismissibleAlert = false;
                    var data  = this.$store.getters.getResults.data;
                    
                    this.sites = data;
                    return this.sites;
                }
            });
        },
        getBuilding(){

            this.buildings = [];
            this.form.building = null;
            this.levels = [];
            this.form.level = '';

            if (this.form.site != null) {
                return this.$store.dispatch(POST_API, {
                    data:{
                        role:this.$store.getters.currentUser.role,
                        site:this.form.site ? this.form.site._id : null
                    },
                    api: '/api/all-buildings'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                    } else {
                        this.buildings    = this.$store.getters.getResults.data;
                        return this.buildings;
                    }
                });
            }
        },
        addRow(){
            this.form.locations.push({name:'', /* unique_id:'',num_area:0, */ type:[], location_type_detail:[]});
        },

        removeRow(index) {
            
            this.form.locations.splice(index, 1);
            
            if (this.form.locations.length ==0) {
                this.form.locations.push({name:'', /* unique_id:'',num_area:0, */ type:[], location_type_detail:[]});
            }
            
        },
        getLevels(){
            this.levels = [];
            this.form.level = '';

            if (this.form.building.basement == 'yes') {

                for (var i = 1; i <= this.form.building.no_of_basements; i++) {
                    this.levels.push('Basement '+i);
                }

            }

            for (var j = 1; j <= this.form.building.no_of_levels; j++) {
                this.levels.push('Level '+j);
            }

            return this.levels;
        },

        checkSiteRoute(){
            var site = this.$route.params.site;
            if (site) {
                this.sites.forEach(item => {

                    if (item._id == site) {
                        this.form.site = item;
                        this.getBuilding().then(() => {
                            var building = this.$route.params.building;
                            if (building) {
                                this.buildings.forEach(item1 => {
                                    if (item1._id == building) {
                                        this.form.building = item1;
                                        this.getLevels();
                                    }
                                })   
                            }
                        });
                    }
                })
            }else{
                //console.log('as')
            }
        },
        breadCrumb(){
          var item = [{
            to:{name:'client-dashboard'},
            text: 'Dashboard',
          },{
            to:{name:'custom-forms'},
            text: 'Custom Forms',
          },{
            to:{name:'custom-form-settings'},
            text:'Form Settings',
          },
          {
            to: {name:'hand-sanitizer-buildings'}, // hyperlink
            text: 'Buildings', // crumb text
            
          },{
            to: {name:'hand-sanitizer-locations',params:{site_id:this.$route.params.site,id:this.$route.params.building}}, // hyperlink
            text: 'Locations', // crumb text
            
          },{
            to: null, // hyperlink
            text: 'Add Location', // crumb text
            
          }];
          return item;
        },
        getForms(){
          return this.$store.dispatch(POST_API, {
             data:{
               id: this.$store.getters.currentUser.organization,
               role: this.$store.getters.currentUser.role,
             },
             api: '/api/get-custom-forms-by-role'
          })
          .then(() => {
              if (this.$store.getters.containsErrors) {
                  this.error_message = this.$store.getters.getErrors;
              } else {
                  var data = this.$store.getters.getResults.data.custom_forms;
                  //console.log(this.formsList);
                  if (this.$store.getters.currentUser.role != 'administrator') {
                      data.sort((a, b) => (a.form.title > b.form.title ? 1 : -1))
                  }

                  var form_types = [];

                  if (this.$store.getters.currentUser.role != 'administrator') {
                    form_types = data.map(item => { return item.form.title});
                    form_types.sort((a,b) => (a > b ? 1 : -1 ));

                  }else{
                    form_types = data.map(item => { return item.title});
                  }


                  var arr = ['Conservancy Supervisor Daily Report','Conservancy Supervisor Daily Report on Public Toilets','Daily Supervisor Checklist','Mosquito Prevention Report','Lactation Checklist','Monthly Inspection','Safety Inspection','Toolbox Meeting','Stock Inventory Form'/* , 'Toilet Inspection Checklist' */]

                  var arr1 = []
                  form_types.forEach(function(item){

                     if (arr.indexOf(item) < 0) {
                        arr1.push(item);
                     }
                  })
                  this.form_types = arr1;


                  if (this.$route.params.form_name != null) {
                    this.form.form_type = [this.$route.params.form_name.replace(/_/g," ")]
                  }

                  return this.form_types;
                  
              }
          });
        },
        checkForm(){
            if (this.form.form_type.indexOf('Flash Times') >=0 || this.form.form_type.indexOf('Soap Dispenser') >=0 || this.form.form_type.indexOf('Floor Traps') >=0 || this.form.form_type.indexOf('Air Freshener') >=0 || this.form.form_type.indexOf('Toilet Seat Sanitizer') >=0) {
                this.req_code = true;
            }else{
                this.req_code = false;
            }
            return this.req_code;
        },
        addLocationType(e, index){
            var temp = {
                name      : e[e.length-1],
                unique_id : '',
                num_area  : 0
            };
            this.form.locations[index].location_type_detail.push(temp);

            const map = {'Male':1,'Female':2,'Handicap':3,'Other':4};

            this.form.locations[index].location_type_detail.sort((a, b) => {
                return map[a.name] - map[b.name];
            });
        },
        removeLocationType(e, index){
            var temp = this.form.locations[index].location_type_detail;
            for(var i = 0; i < temp.length; i++){
                if(this.form.locations[index].location_type_detail[i].name == e){
                    this.form.locations[index].location_type_detail.splice(i,1)
                }
            }
        },
        sortLocType(index){
            const map = {'Male':1,'Female':2,'Handicap':3,'Other':4};

            this.form.locations[index].type.sort((a, b) => {
                return map[a] - map[b];
            });

        },
        
    },
    mounted(){
        Bus.$on('update-image', (data) => {
            this.form.locations.[data.index].graphics = data.data;
        })
        this.getForms();
        this.allSites().then(() => {
            
            this.checkSiteRoute();
        });

    }
}
</script>
